import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../seo"
import style from "./page.module.css"
import PageBlocks from "../PageBlocks"

interface IPageProps {
  data: GatsbyTypes.PageQuery;
}

const Page: FunctionComponent<IPageProps> = ({data}) => {
  const page = data.page
  const title = (page.openGraph && page.openGraph.title) || page.title
  const description = (page.openGraph && page.openGraph.description) || ""
  const openGraphImage = (page.openGraph && page.openGraph.image && page.openGraph.image.asset && page.openGraph.image.asset.fixed.src)
  return(
    <Layout>
      <SEO title={title} image={openGraphImage && openGraphImage} description={description} slug={data.page.slug.current} />
      <div className={style.main}>
        <PageBlocks page={page} />
      </div>
    </Layout>
  );
}

export default Page

export const pageQuery = graphql`
  query Page($id: String) {
    page: sanityPage(id: {eq: $id}) {
      slug {
        current
      }
      _id
      title
      openGraph {
        title
        description
        image {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
      _rawContent(resolveReferences: {maxDepth: 10})
    }
  }
`